<template>
    <div class="card">
        <div class="card-body">
            <h1 class="card-title">
                <i class="mdi mdi-home"></i>
                Branches
            </h1>

            <p class="card-description">List of branches</p>

            <div class="row">
                <div class="col-12">
                    <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                        <div class="row">
                            <div class="col-xs-12 col-3">
                                <button type="button" class="btn btn-primary" @click="addBranch">
                                    <i class="mdi mdi-plus"></i>
                                    Add Branch
                                </button>
                            </div>

                            <div class="col-xs-12 col-3">
                                <div class="select2-wrapper">
                                    <select class="select-2 form-control"
                                        name="constituency_id"
                                        ref="constituency"
                                        data-placeholder="Select A Constituency">
                                        <option value="">Select a constituency</option>
                                        <option v-for="(constituency, index) in constituencies"
                                            :value="constituency.id"
                                            :key="index">{{ constituency.name }}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-xs-12 col-6">
                                <toolbar
                                    @show="val => params.per_page = val"
                                    @search="val => params.keyword = val" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <template v-if="branches.length">
                                    <div class="table-responsive">
                                        <branches-table
                                            @edit="editBranch"
                                            :branches="branches"
                                            @delete="deleteBranch" />
                                    </div>

                                    <app-pagination
                                        v-if="pageDetails && pageDetails.total"
                                        :pageDetails="pageDetails"
                                        @navigate="getBranches($event)" />
                                </template>

                                <template v-else>
                                    <p class="lead mt-5 mb-5 text-center">There are no branches in the system</p>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal
            :show="showModal"
            :branch="modalBranch"
            @save-branch="saveBranch"
            @update:show="val => showModal = val"></modal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Modal from './Modal';
import BranchesTable from './Table';

export default {
    components: {
        Modal,
        BranchesTable
    },

    computed: {
        ...mapGetters({
            regions: 'Regions/getAll',
            constituencies: 'Constituencies/getAll',
            branches: 'Branches/getAll',
            pageDetails: 'Branches/getPageDetails'
        })
    },

    data() {
        return {
            showModal: false,
            modalBranch: {},
            params: {
                paginate: true,
                per_page: null,
                keyword: null,
                constituency_id: ""
            }
        }
    },

    watch: {
        params: {
            deep: true,
            handler() {
                this.getBranches();
            }
        }
    },

    methods: {
        ...mapActions({
            store: 'Branches/store',
            load: 'Branches/all',
            update: 'Branches/update',
            delete: 'Branches/delete',
            loadRegions: 'Regions/all',
            loadConstituencies: 'Constituencies/all'
        }),

        /**
         * Add a branch
         *
         * @return {Undefined}
         */
        addBranch() {
            this.modalBranch = {};
            this.showModal = true;
        },

        /**
         * Add a new branch
         *
         * @param {Object} branch Branch to be added
         * @return {Undefined}
         */
        saveBranch(branch) {
            let keyword = 'added';
            let savingMethod = this.store;
            let payload = { data: branch };

            if (branch.id !== 'undefined' && branch.id) {
                keyword = 'updated';
                payload.id = branch.id;
                savingMethod = this.update;
            }

            savingMethod(payload)
                .then(response => {
                    this.notify(`The branch was ${keyword} successfully.`);
                    this.getBranches();
                    this.showModal = false;
                    this.modalBranch = {};
                })
                .catch(errors => this.notify(this.buildErrors(errors), 'error'));
        },

        /**
         * Set up a branch and show edit modal for editing it
         *
         * @param {Object} branch Branch to be updated
         * @return {Undefined}
         */
        editBranch(branch) {
            this.showModal = true;
            this.modalBranch = { ...branch };
        },

        /**
         * Confirm the delete and handle the delete action of a branch
         *
         * @param {Number} id Id of branch to be deleted
         * @return {Undefined}
         */
        deleteBranch(id) {
            Swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover this branch and all associated data!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes - Delete it.',
                confirmButtonClass: 'bg-danger',
                cancelButtonText: 'No - Cancel Delete'
            })
            .then(response => {
                if (response.value) {
                    this.delete(id)
                        .then(() => {
                            this.notify('The branch was deleted successfully.');
                            this.getBranches();
                        })
                        .catch(errors => this.notify(this.buildErrors(errors), 'error'))
                }
            });
        },

        /**
         * Load branches
         *
         * @param {Object} query Query params
         */
        getBranches(query = {}) {
            for (let param of Object.keys(this.params)) {
                if (this.params[param]) {
                    query[param] = this.params[param];
                }
            }

            this.load(query)
                .catch(errors => this.notify(this.buildErrors(errors), 'error'));
        },

        /**
         * Load all regions
         */
        getRegions(query = {}) {
            this.loadRegions(query)
                .catch(errors => this.notify(this.buildErrors(errors), 'error'));
        },

        /**
         * Load all constituencies
         */
        getConstituencies(query = {}) {
            this.loadConstituencies(query)
                .catch(errors => this.notify(this.buildErrors(errors), 'error'));
        },

        /**
         * Register event listeners to handle select 2
         *
         * @return {Undefined}
         */
        registerEventListeners() {
            $(this.$refs.constituency).on('change', this.constituencyChanged);
        },

        /**
         * Handle the constituency change event
         *
         * @param {Object} event Change event
         */
        constituencyChanged(event) {
            this.params.constituency_id = event.target.value;
        }
    },

    mounted() {
        this.getRegions();
        this.getBranches();
        this.getConstituencies();
        this.registerEventListeners();
    }
}
</script>