<template>
    <table class="table table-striped dataTable no-footer" role="grid">
        <thead>
            <tr role="row">
                <td>Name</td>
                <td>Branch Code</td>
                <td>Electoral Area</td>
                <td>Constituency</td>
                <td>Voters</td>
                <td>Created At</td>
                <td>Updated At</td>
            </tr>
        </thead>

        <tbody>
            <tr role="row" v-for="(branch, index) in branches" :key="index">
                <td>{{ branch.name }}</td>
                <td>{{ branch.code ? branch.code : 'N/A' }}</td>
                <td>{{ branch.electoral_area ? branch.electoral_area.name : 'N/A' }}</td>
                <td>{{ branch.electoral_area && branch.electoral_area.constituency ? branch.electoral_area.constituency.name : 'N/A' }}</td>
                <td>{{ branch.registered_voters }}</td>

                <td>{{ branch.created_at | formattedDateTime }}</td>
                <td>{{ branch.updated_at | formattedDateTime }}</td>
                <td>
                    <button class="btn btn-warning btn-action mr-3" @click="$emit('edit', branch)" data-toggle="popover" data-content="Edit Branch">
                        <i class="mdi mdi-pencil"></i>
                    </button>

                    <button class="btn btn-danger btn-action" @click="$emit('delete', branch.id)" data-toggle="popover" data-content="Delete Branch">
                        <i class="mdi mdi-trash-can"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    export default {
        props: ['branches']
    }
</script>