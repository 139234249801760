<template>
    <modal
        :force="true"
        :show="show"
        :title="title"
        :ok-text="title"
        :close-when-cancel="true"
        @cancel="$emit('update:show', false)"
        @ok="saveBranch"
        @update:show="$emit('update:show', $event)">
			<div class="row justify-content-center">
				<div class="col-xs-12 col-10">
                    <div class="form-group">
                        <label for="name">Name</label>
                        <input
                            id="name"
                            type="text"
                            name="name"
                            v-model="payload.name"
                            class="form-control"
                            v-validate="rules.name"
                            placeholder="Name">

                        <error-label :message="errors.first('name')" />
                    </div>

                    <div class="form-group">
                        <label for="code">Code</label>
                        <input
                            id="code"
                            type="text"
                            name="code"
                            v-model="payload.code"
                            class="form-control"
                            placeholder="Branch Code">
                    </div>

                    <div class="form-group" :class="getFieldClass('constituency_id')">
                        <label>Constituency</label>

                        <div class="select2-wrapper">
                            <select class="select-2 form-control"
                                name="constituency_id"
                                ref="constituency"
                                v-validate="rules.constituency_id">
                                <option value="">Select a constituency</option>
                                <option v-for="(constituency, index) in constituencies"
                                    :value="constituency.id"
                                    :key="index">{{ constituency.name }}</option>
                            </select>
                        </div>

                        <error-label :message="errors.first('name')" />
                    </div>
				</div>
			</div>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },

        branch: {
            type: Object,
            default: {}
        }
    },

    computed: {
        ...mapGetters({
            constituencies: 'Constituencies/getAll',
        }),

        /**
         * Check if the current operation is an update.
         *
         * @return {Boolean}
         */
        isUpdate() {
            return Object.keys(this.branch).length > 0;
        },

        /**
         * Title for the modal depending on the current operation.
         *
         * @return {String}
         */
        title() {
            return this.isUpdate ? 'Edit Branch' : 'Add Branch';
        }
    },

    data() {
        return {
            rules: {
                constituency_id: 'required',
                name: 'required'
            },

            payload: {
                name: '',
                code: '',
                constituency_id: ''
            },
        }
    },

    watch: {
        /**
         * Toggle the id property depending on the save operation. Update or Save.
         *
         * @param {Boolean} value
         * @return {Undefined}
         */
        show(value) {
            this.resetModal();

            if (this.isUpdate) {
                this.getBranchDetails();
                this.$set(this.payload, 'id', this.branch.id);

                this.$nextTick(() => {
                    $(this.$refs.region).val(this.branch.constituency ? this.branch.constituency.region_id : '').change();
                });
            } else {
                this.$delete(this.payload, 'id');
            }
        }
    },

    methods: {
        ...mapActions({
            loadConstituencies: 'Constituencies/all'
        }),

        /**
         * Reset the modal.
         *
         * @return {Undefined}
         */
        resetModal() {
            this.payload.name = '';
            this.payload.code = '';
            this.payload.constituency_id = '';
            $(this.$refs.constituency).val('').trigger('change');
            this.$nextTick(() => this.errors.clear());
        },

        /**
         * Register event listeners to handle select2 changes
         */
        registerEventListeners() {
            $(this.$refs.constituency).on('change', this.constituencyChanged);
        },

        /**
         * Handle the change of a constituency
         *
         * @param {Object} event Change event
         */
        constituencyChanged(event) {
            this.payload.constituency_id = event.target.value;
        },

        /**
         * Get constituencies
         *
         * @return {Undefined}
         */
        getConstituencies() {
            this.loadConstituencies()
                .then(() => {
                    $(this.$refs.constituency).val(this.payload.constituency_id).change();
                })
                .catch(errors => this.notify(this.buildErrors(errors), 'error'));
        },

        /**
         * Validate supplied data and emit event to trigger save of branch
         *
         * @return {Undefined}
         */
        saveBranch() {
            this.$validator.validateAll()
                .then((response) => {
                    if (response) {
                        this.$emit('save-branch', this.payload);
                    } else {
                        let messageConf = this.buildErrors(this.$validator.errors);

                        this.notify(messageConf, 'error');
                    }
                });
        },

        /**
         * Load branch details into editable data
         */
        getBranchDetails() {
            this.payload = {
                ...this.branch
            };

            $(this.$refs.constituency).val(this.payload.constituency_id).trigger('change');
        }
    },

    mounted() {
        this.registerEventListeners();
    }
}
</script>